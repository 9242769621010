<template>
  <div
    class="layout-default"
    id="layout-default"
    :class="[layoutClass]"
    :style="{ background: `url(${adBanner}) no-repeat center top` }"
  >
    <header style="height: 80px">
      <HeaderVll></HeaderVll>
    </header>
    <BackTop></BackTop>
    <router-view></router-view>
    <!--    <FooterVll></FooterVll>-->
    <!-- <HomeFooterBanner></HomeFooterBanner> -->
    <FooterDefault></FooterDefault>
  </div>
</template>

<script>
import HeaderDefault from "@/Layouts/components/HeaderDefault";
import FooterDefault from "@/Layouts/components/FooterDefault";
import BackTop from "@/Layouts/components/BackTop";
import HeaderVll from "@/Layouts/components/HeaderVll/HeaderVll.vue";
import FooterVll from "@/Layouts/components/FooterVll/FooterVll.vue";
import HomeFooterBanner from "@/Layouts/components/FooterBanner/footerBanner/index.vue";
/**
 * @author  XuHongli
 * @date  2022/12/22 11:55
 * @version 1.0
 * @description
 */
export default {
  name: "DefaultLayout",
  components: {
    FooterVll,
    HeaderVll,
    BackTop,
    FooterDefault,
    HeaderDefault,
    HomeFooterBanner,
  },
  data() {
    return {
      adBanner: "",
    };
  },

  computed: {
    // Sets layout's element's class based on route's meta data.
    layoutClass() {
      return this.$route.meta.layoutClass;
    },
  },
};
</script>

<style scoped lang="scss">
</style>
