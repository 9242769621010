/**
* @author  XuHongli
* @date  2022/2/17 13:58
* @version 1.0
* @description Article Temp
*/

<template>
  <div class="ArticleTpl marginAuto contWidth1440">
    <a-row type="flex" justify="center" :class="{ reverse: reverse }">
      <a-col
        :xl="12"
        :lg="12"
        :span="24"
        style="display: flex; justify-content: center"
      >
        <div class="tpl-text animation-duration-1 wow" data-wow-duration="1s">
          <slot name="title" />
          <ul class="list">
            <li
              v-for="(item, index) in tplData.list"
              :key="index"
              class="listItem wow animate__zoomIn"
              data-wow-duration="1s"
            >
              <img src="/mp/icon/right.png" alt="" />
              {{ item }}
            </li>
          </ul>
          <slot name="ad" />
        </div>
      </a-col>
      <a-col :xl="12" :lg="12" :span="22">
        <div class="ttps" style="position: relative">
          <img
            v-if="!Array.isArray(data.imgSrc)"
            class="tplImg wow animation-duration-1"
            :src="tplData.imgSrc"
             data-wow-duration="1s"
          />
          <div v-if="tplData.num" class="tps">
            <countTo
              :startVal="startVal"
              :endVal="endVal"
              :duration="2000"
            ></countTo>
          </div>
          <ul v-else class="flash" style="margin: 0">
            <li
              v-for="(item, index) in tplData.imgSrc"
              :key="'liImg' + index"
              :class="{ show: index === ulIndex }"
            >
              <!-- <img v-if="item" :src="item" class="tplImg" /> -->
            </li>
            <img
              class="tplImg"
              style="visibility: hidden"
              :src="tplData.imgSrc[0]"
            />
            <!-- <img
              class="tplImg"
              style="position: absolute; left: 0; top: 0; z-index: -1"
              src="/images/bg.png"
              v-if="mapBg"
            /> -->
          </ul>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import WOW from "wowjs";
import countTo from "vue-count-to";
export default {
  name: "ArticleTpl",
  props: {
    reverse: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      required: true,
      // default: () => { return { list: [], imgSrc: '', title: '' } }
    },
    speed: {
      type: Number,
      default: 5000,
    },
    mapBg: {
      type: Boolean,
      default: false,
    },
  },
  components: { countTo },
  mounted() {
    new WOW.WOW().init();
  },
  data() {
    return {
      tplData: {},
      ulIndex: 0,
      time: "",
      startVal: 0,
      //数字结束
      endVal: 168080,
    };
  },
  created() {
    if (Object.keys(this.data).length > 0) {
      this.tplData = this.data;
    }
    if (Array.isArray(this.data.imgSrc)) {
      this.$nextTick(() => {
        this.loop();
      });
    }
  },
  methods: {
    loop() {
      this.time = setInterval(() => {
        if (this.ulIndex < this.data.imgSrc.length - 1) {
          this.ulIndex += 1;
        } else {
          this.ulIndex = 0;
        }
      }, this.speed);
    },
  },
  beforeDestroy() {
    clearInterval(this.time);
  },
};
</script>

<style lang="scss" scoped>
.ArticleTpl {
}
.ttps {
  position: relative;
}
.tps {
  position: absolute;
  bottom: 110px;
  left: 120px;
}
.flash {
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 720px;
  max-height: 510px;
}
.flash li {
  position: absolute;
  transition: all 0.5s ease-in-out;
  opacity: 0;
}
.flash li.show {
  opacity: 1;
}
.reverse {
  flex-direction: row-reverse !important;
}
.tplImg {
  width: 90%;
  position: relative;
}
.tpl-text {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  text-align: left;
}
ul {
  .listItem {
    font-size: 18px;
    margin-top: 13px;
  }
}
</style>
