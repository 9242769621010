/**
 * @author  XuHongli
 * @date  2023/2/20 13:46
 * @version 1.0
 * @description HomeData.js
 */
export const cardData = [
  {
    index: '01',
    title: ['营销利器｜5G消息  ', '低成本精准获客'],
    desc: '长期积累的精准数据和营销方法，通过5G消息可快速触达客户，持续改进选品策略，大幅提升营销效果'
  },
  {
    index: '02',
    title: ['开店必看 |万鲸供应链', '千万级SKU，一键代发'],
    desc: '无货源快速开店，助力新的社交电商卖家快速进入流量运营阶段'
  },
  {
    index: '03',
    title: ['重磅功能｜视频号+', '小程序，打造流量闭环'],
    desc: '利用视频号的视频或直播为小程序商城或小商店引流，用户在线购买，减少转化流失'
  },
  {
    index: '04',
    title: ['免费领取｜私域运营课程+', '直播运营分享'],
    desc: '总结了一套全干货的私域运营课程和直播运营打法，会定期产出高质量运营报告，快来领取吧'
  }
]
export const tplData1 = {
  list: ['提供多行业精美模板免费使用', '支持首页可视DIY', '多种支付方式任意配置', '保障您的电商网站流畅展示'],
  imgSrc: ['images/home/1.png', 'images/home/2.png', 'images/home/3.png']
}
export const tplData2 = {
  list: ['对接包括抖音，视频号，小红书等主流社交平台', '可用视频、直播、传统网络广告、5G消息等多种推广方式', '丰富的运营经验助您低成本高转化'],
  imgSrc: 'images/home/home1.png',
  num:168080
}
// export const tplData3 = {
//   list: ['智能选品，热款、爆款、一网打尽', '专属智能物流高效出库，快速运输，畅行无阻', '千万量级热卖品类数据，助您一键开店，轻松运营  '],
//   imgSrc: ['images/1.png', 'images/2.png', 'images/3.png', 'images/4.png', 'images/5.png']
// }
export const tplData3 = {
  list: ['智能选品，全网热款、爆款一网打尽', '专属智能物流高效出库，快速运输，及时送达', '亿级订单商品数据分析，助您轻松运营，尽在掌握  '],
  imgSrc: 'images/home/home2.png'
}
export const tplData4 = {
  list: ['通过分销拓客，建立在线分销网络，让你的产品可以快速布局在分销渠道的每个角落', '极具创意的社交化运营方案，能够让网红、达人、粉丝、客户将你的产品推荐给更多人', '丰富的营销工具营造火爆的购物氛围，提升购买成功率'],
  imgSrc: 'images/home/home3.png',
  // ims: "/mp/VirHome/0.jpg"
}
export const tplModel = ['images/home/model/tpl1.png', 'images/home/model/tpl2.png', 'images/home/model/tpl3.png', 'images/home/model/tpl4.png', 'images/home/model/tpl5.png', 'images/home/model/tpl6.png', 'images/home/model/tpl7.png']


export const listdata = [
  {
    title: '国民内衣品牌私域“二次创业”',
    cont: `线上线下两条腿走路，在新评判浪潮下打响反击战 营收增长50%+`,
    name: '张先生',
    txt: '内衣类目商家',
    img: 'images/home/Oval.png'
  },
  {
    title: '烘焙界海底捞，不靠平台也能赢  烘焙私域样板打法，一年卖出500万个蛋糕是什么体验？',
    cont: ' 公众号吸粉1000万+',
    name: '刘女士',
    txt: '烘焙类目商家',
    img: 'images/home/Oval1.png'
  },
  {
    title: '30万人网上营销，月入千万“万鲸云分销玩法提供了低成本曝光和裂变的方式”',
    cont: '分销人数达到30万+',
    name: '陈先生',
    txt: '酒水类目商家',
    img: 'images/home/Oval2.png'
  },
  {
    title: '有货源没流量？母婴店如何破局',
    cont: '他用万鲸云快速实现私域闭环，转战私域如何破局？年度累计GMV2000万+',
    name: '王女士',
    txt: '母婴类目商家',
    img: 'images/home/Oval3.png'
  },

]