/**
 * @author  XuHongli
 * @date  2022/12/28 11:51
 * @version 1.0
 * @description
 */
const ServerHoverList = [
  {
    title: '一件代发',
    subTitle: '自动订单管理方便快捷',
    icon: process.env.VUE_APP_STATIC_FILE_URL + '/mp/ProductServer/cp_znkf_02.png',
    path: '/oversea'
  },
  {
    title: '媒体直播',
    subTitle: '社交媒体的粉丝进行互动',
    icon: process.env.VUE_APP_STATIC_FILE_URL + '/mp/ProductServer/head_guanggao.png',
    path: '/liveSales'
  },
  {
    title: '智能广告(敬请期待)',
    subTitle: '大数据分析投放人群',
    icon: process.env.VUE_APP_STATIC_FILE_URL + '/mp/ProductServer/head_guanggao.png',
  },
  {
    title: '营销推广(敬请期待)',
    subTitle: '实现流量增长',
    icon: process.env.VUE_APP_STATIC_FILE_URL + '/mp/ProductServer/head_guanggao.png',
  },
]

export default ServerHoverList
