/**
* @author  XuHongli
* @date  2022/2/17 13:58
* @version 1.0
* @description 文章标题
*/
<template>
  <h3 class="ArticleTitle"><slot></slot></h3>
</template>

<script>
export default {
  name: 'ArticleTitle'
}
</script>

<style scoped>
.ArticleTitle:before{
  content: " ";
  display: inline-block;
  width: 111px;
  height: 8px;
  background: url('/images/title_left.png') no-repeat;
  margin-right: 8%;
  background-size: 100%;
}
.ArticleTitle:after{
  content: " ";
  display: inline-block;
  width: 111px;
  height: 8px;
  background: url('/images/title_right.png') no-repeat;
  margin-left: 8%;
  background-size: 100%;
}
.ArticleTitle{
  font-family: "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  font-size: 4.4rem;
  font-weight: bolder;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
}
</style>
