<template>
  <div class="HomeHeader" :style="{ boxShadow: `${isBoxShadow}` }">
    <div class="HomesRight">
      <div class="logo">
        <img src="~@/assets/logo.png" alt="万鲸科技" />
      </div>
      <div class="HomesT">
        <ul class="nav-menu">
          <li
            v-for="(item, index) in Routers"
            :key="index"
            :class="{ active: hoverKey === index }"
            @mouseenter="enterLi(item.name)"
            @click="handleMenuItems(item.path)"
          >
            <span>
              {{ item.name }}
              <a-icon
                style="color: #333; font-size: 10px"
                v-if="item.name === '产品服务'"
                :type="ShowProductServerHover ? 'up' : 'down'"
              />
            </span>
          </li>
        </ul>
        <div class="action">
          <!--    未登录显示注册按钮    -->
          <template v-if="!isLogin">
            <a-button class="tryBtn" @click="open"> 免费试用 </a-button>
          </template>
          <a-button class="login" @click="open">
            {{ isLogin ? "进入管理后台" : "登录" }}
          </a-button>
          <!--    已登录显示推出按钮    -->
          <template v-if="isLogin">
            <span class="mx-10 text-dark">|</span>
            <span @click="logOut" class="mr-20 text-dark">退出</span>
          </template>
        </div>
      </div>
    </div>
    <div class="burger">
      <div class="top-line" />
      <div class="middle-line" />
      <div class="bottom-line" />
    </div>
    <ProductServerHover v-model="ShowProductServerHover"></ProductServerHover>
    <SolutionHover v-model="ShowSolutionHover"></SolutionHover>
  </div>
</template>

<script>
/**
 * @author XuHongli
 * @description 海外版首页 静态页面头部导航条
 **/
import HeaderConfig from "@/config/HeaderConfig";
import Cookies from "js-cookie";
import SolutionHover from "@/Layouts/components/SolutionHover/index.vue";
import ProductServerHover from "@/Layouts/components/ProductServerHover/index.vue";
import find from "lodash.find";
import ProductRouter from "@/router/ProductRouter";
import ServerRouter from "@/router/ServerRouter";
import SolutionRouter from "@/router/SolutionRouter";

export default {
  name: "HeaderVll",
  components: { SolutionHover, ProductServerHover },
  data() {
    return {
      ShowProductServerHover: false,
      ShowSolutionHover: false,
      /** Node 环境 */
      process: process,
      /** 定时器 (确认是否登录) */
      timer: null,
      /** 是否登录 */
      isLogin: false,
      /** 路由列表 */
      Routers: [],
      /** 当前路由 */
      currentRoute: "",
      /** burger按钮 element */
      burger: null,
      /** navMenu列表 element */
      navMenu: null,
      /** navMenuItems */
      navMenuItems: null,
      /** 固定标题样式 */
      isBoxShadow: ''
    };
  },
  computed: {
    hoverKey() {
      let tempKey = null;
      this.Routers.forEach((item, index) => {
        if (this.$route.path === item.path) {
          tempKey = index;
        }
      });

      // 寻找是否在产品服务里面
      if (tempKey === null) {
        let findObj = find([...ProductRouter, ...ServerRouter], (item) => {
          return this.$route.path === item.path;
        });
        if (findObj !== undefined) {
          return 2;
        }
      }

      // 寻找是否在解决方案路由内
      if (tempKey === null) {
        let findObj = find(SolutionRouter, (item) => {
          return this.$route.path === item.path;
        });
        if (findObj !== undefined) {
          return 3;
        }
      }

      return tempKey;
    },
  },

  created() {
    console.log("homeHeaderCreate");
    this.Routers = HeaderConfig;
    this.currentRoute = this.$route.path;
  },
  mounted() {
    // 监听滚动条
    window.addEventListener('scroll', this.handleScroll)

    this.timer = setInterval(() => {
      this.isLogin =
        Cookies.get("access_token", {
          domain: process.env.VUE_APP_HOST,
          path: "/",
        }) !== undefined &&
        Cookies.get("access_token", {
          domain: process.env.VUE_APP_HOST,
          path: "/",
        }) !== "";
    }, 100);
    this.burger = document.querySelector(".HomeHeader .burger");
    this.navMenu = document.querySelector(".HomeHeader .nav-menu");
    this.navMenuItems = document.querySelectorAll(".HomeHeader .nav-menu li");
    this.burgerAddEventListener();
  },
  // 离开时销毁
  beforeDestroy() {
    window.removeEventListener('scroll',this.handleScroll)
  },
  methods: {
    handleScroll() {
      //滚动条在y轴上的滚动距离
      let scrollTop = document.documentElement.scrollTop||document.body.scrollTop;
      //变量windowHeight是可视区的高度
      let windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
      //变量scrollHeight是滚动条的总高度
      let scrollHeight = document.documentElement.scrollHeight||document.body.scrollHeight
      //滚动条到底部的条件
      // console.log('scrollTop', scrollTop)
      // console.log('windowHeight', windowHeight)
      // console.log('scrollHeight', scrollHeight)

      this.isBoxShadow = (scrollTop > 0 && windowHeight > 0 && scrollHeight > 0) ? '0 2px 4px rgba(0, 0, 0, 0.2)' : 'none'
    },
    open() {
      window.open(process.env.VUE_APP_SET_URL, "_blank");
    },
    go() {
      window.open(process.env.VUE_APP_SET_URL, "_blank");
    },
    enterLi(name) {
      this.ShowProductServerHover = name === "产品服务";
      this.ShowSolutionHover = name === "解决方案";
    },
    /** 退出登录 */
    logOut() {
      console.log(process.env.VUE_APP_HOST, "domain");
      let that = this;
      this.$confirm({
        title: "提示",
        content: "确定要退出登录吗？",
        cancelText: "取消",
        okText: "确认",
        onOk() {
          Cookies.remove("access_token", {
            domain: process.env.VUE_APP_HOST,
            path: "/",
          });
          that.isLogin = false;
        },
        onCancel() {},
      });
    },
    /** 点击MenuItems */
    handleMenuItems(path) {
      this.currentRoute = path;
      if (path.indexOf("http") > -1) {
        window.open(path, "_blank");
      } else {
        this.$router.push(path);
      }
    },
    /** 注册监听 */
    burgerAddEventListener() {
      if (this.burger && this.navMenu && this.navMenu) {
        this.burger.addEventListener("click", () => {
          // 汉堡按钮
          if (this.burger) this.burger.classList.toggle("active");
          // 导航菜单开关
          if (this.navMenu) this.navMenu.classList.toggle("open");

          // 菜单项动画
          this.navMenuItems.forEach((item, index) => {
            // 如果已添加animation,先取消
            if (item.style.animation) {
              item.style.animation = "";
            } else {
              item.style.animation = `0.3s ease-in slideIn forwards ${
                index * 0.1 + 0.3
              }s`;
            }
          });
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.HomeHeader {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2)
}
</style>
