/**
 * @author  XuHongli
 * @date  2022/12/30 17:15
 * @version 1.0
 * @description
 */
import request from '@/utils/axios'

/**
 * helpCenter文档搜索
 * @param keywords {string}
 * @return {*}
 */
export function helpCenterSearch(keywords) {
  return request({
    url: '/system/category/list/' + encodeURIComponent(keywords),
    method: 'get'
  })
}

/**
 * ProblemComponents 查询树形分类
 * @return {*}
 */
export function inquireTree(id) {
  return request({
    url: `/system/category/queryTreeCate?parentId=` + id,
    method: 'get'
  })
}

/**
 * ProblemComponents 帮助中心分页列表
 * @return {*}
 */
export function getDetailsList(id, data) {
  return request({
    url: `/system/helpcenter/app_list?parentId=` + id,
    method: 'get',
    params: data
  })
}

/**
 * ProblemComponents 企业动态详情
 * @return {*}
 */
export function gethelpcenter(id) {
  return request({
    url: `/system/helpcenter/` + id,
    method: 'get'
  })
}


export function getAdd(data) {
  return request({
    url: '/system/mpgather/add',
    method: 'post',
    data: data
  })
}
