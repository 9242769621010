<template>
  <div>
    <div class="section_banner-7 bg_23homepage wf-section">
      <div class="container_banner w-container">
        <div class="hero-warp_banner-4">
          <div class="node content-wrap_banner">
            <!--            <img src="https://shoplineapp.cn/images/国内领先的智慧SaaS建站系统-1.webp" class="image-531">-->
            <h1 class="heading_banner-h1-5 bg_23homepage">懂私域运营的服务商<br>无货源开店 快速启动社交电商</h1>
            <p class="paragraph_banner-5 bg_23homepage">万鲸深耕新零售领域，赋能众多企业客户，深挖全域业绩增长 把粉丝变成终身客户，为卖家与消费者建立可持续的客户关系</p>
            <div class="form-block-4 homepage">
              <div class="email-input-wrap2 homepage bg_23homepage w-embed">
                <input style="height: 100%;width:100%;background:transparent;" class="email-input__input" placeholder="输入邮箱/手机号">
              </div>
              <a id="slSubmitBtn__0" data-agl-cvt="5" href="https://console.wanjingai.com/user/register" class="submit-btn homepage w-button">14天免费试用</a>
            </div>
          </div>
          <div class="node image-wrap_banner">
            <img src="https://shoplineapp.cn/images/homepage_banner_pic.webp" class="image_banner-3 bg_23homepage">
          </div>
        </div>
      </div>
    </div>
    <div class="section_body-16 home wf-section">
      <div class="container_body w-container">
        <div class="div-block-565">
          <div class="w-layout-grid grid-74">
            <div class="node div-block-630 homepage icon-4_homepage">
              <img src="https://shoplineapp.cn/images/icon_blue_num_01.webp" class="image500">
              <h3 class="heading_body_iconhomepage">营销利器｜低成本精准获客</h3>
              <p class="paragraph_body-p2-7 homepage">长期积累的广告营销方法，通过5G消息可快速触达客户，持续改进选品策略，大幅提升营销效果</p>
              <a href="https://console.wanjingai.com/user/register" class="link-block_banner-1 w-inline-block">
                <div class="text-block_banner-5">了解更多 →</div>
              </a>
            </div>
            <div class="node div-block-630 homepage icon-4_homepage">
              <img src="https://shoplineapp.cn/images/icon_blue_num_02.webp" class="image500">
              <h3 class="heading_body_iconhomepage">开店必看 | 千万级SKU，一键代发</h3>
              <p class="paragraph_body-p2-7 homepage">无货源快速开店，助力新的社交电商卖家快速进入流量运营阶段</p>
              <a href="https://console.wanjingai.com/user/register" class="link-block_banner-1 w-inline-block">
                <div class="text-block_banner-5">了解更多 →</div>
              </a>
            </div>
            <div class="node div-block-630 homepage icon-4_homepage">
              <img src="https://shoplineapp.cn/images/icon_blue_num_03.webp" class="image500">
              <h3 class="heading_body_iconhomepage">重磅功能｜视频号+小程序，打造流量闭环</h3>
              <p class="paragraph_body-p2-7 homepage">利用视频号的视频或直播为小程序商城或小商店引流，用户在线购买，减少转化流失</p>
              <a href="https://console.wanjingai.com/user/register" class="link-block_banner-1 w-inline-block">
                <div class="text-block_banner-5">了解更多 →</div>
              </a>
            </div>
            <div class="node div-block-630 homepage icon-4_homepage">
              <img src="https://shoplineapp.cn/images/icon_blue_num_04.webp" class="image500">
              <h3 class="heading_body_iconhomepage">免费资料下载｜私域运营课程+直播运营分享</h3>
              <p class="paragraph_body-p2-7 homepage">总结了一套全干货的私域运营课程和直播运营打法，会定期产出高质量运营报告，快来领取吧</p>
              <a href="https://console.wanjingai.com/user/register" class="link-block_banner-1 w-inline-block">
                <div class="text-block_banner-5">了解更多 →</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @author  XuHongli
 * @date  2023/3/3 16:40
 * @version 1.0
 * @description index.vue
 */
export default {
  name: 'HomeShopLine'
}
</script>

<style scoped lang="scss">
@import "@/styles/shopline.scss";
</style>
