<template>
  <div
    class="ProductServerHover"
    v-show="value"
    @mouseenter="emitEnter"
    @mouseleave="emitLeave"
  >
    <div style="width: 58%; display: flex">
      <div class="drop-menu">
        <div
          :class="['drop-menu', { active: dropKey === 0 }]"
          @mouseenter="dropKey = 0"
        >
          按产品
          <a-icon type="right" />
        </div>
        <div
          :class="['drop-menu', { active: dropKey === 1 }]"
          @mouseenter="dropKey = 1"
        >
          按模式
          <a-icon type="right" />
        </div>
      </div>
      <div class="dropdown_left">
        <div class="dropdown_left_small">
          <a
            v-for="(item, index) in list[dropKey]"
            :key="index"
            :href="item.path"
            :class="['dropdown_li', { is_active: $route.path === item.path }]"
          >
            <div>
              <div class="dropdown_headline">{{ item.title }}</div>
              <div class="dropdown_subtitle">{{ item.subTitle }}</div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @author  XuHongli
 * @date  2022/12/28 11:35
 * @version 1.0
 * @description
 */
import ProductHoverList from "@/Layouts/components/ProductServerHover/ProductHoverList";
import ServerHoverList from "@/Layouts/components/ProductServerHover/ServerHoverList";

export default {
  name: "ProductServerHover",
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      dropKey: 0,
      ProductHoverList,
      ServerHoverList,
      list: [ProductHoverList, ServerHoverList],
    };
  },
  methods: {
    emitEnter() {
      this.$emit("input", true);
    },
    emitLeave() {
      this.$emit("input", false);
    },
  },
};
</script>

<style scoped lang="scss">
.drop-menu {
  border-right: 1px solid rgba(0, 0, 0, 0.08);
  .drop-menu {
    width: 200px;
    height: 50px;
    display: flex;
    padding: 0 20px;
    align-items: center;
    font-weight: 600;
    font-size: 18px;
    justify-content: space-between;
    &.active {
      background-color: #f3cbae;
      color: #ff6a00;
    }
  }
}
.ProductServerHover {
  // width: 894px;
  width: 100%;
  //background: #fff;
  //box-shadow: 0 2px 6px 0 rgba(0,0,0,.08);
  background-color: #f6f8fa;
  position: absolute;
  top: 80px;
  left: 0;
  //border-top:1px solid rgba(0,0,0,.08);
  z-index: 99999;
  box-sizing: border-box;
  padding: 30px 30px 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.dropdown_right {
  margin-left: 20px;
}
.dropdown_left,
.dropdown_right {
  margin-left: 20px;
  width: 680px;
  .dropdown_left_title {
    height: 30px;
    font-size: 18px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN, serif;
    font-weight: 400;
    color: #555;
    line-height: 10px;
    border-bottom: 1px solid #eee;
    margin-bottom: 15px;
  }
  .dropdown_left_small {
    display: flex;
    flex-wrap: wrap;
  }
}
.dropdown_li {
  transition: all 0.5s;
  width: 210px;
  // height: 66px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin-right: 10px;
  padding: 18px 0 18px 18px;
  &:hover {
    transition: all 0.4s ease;
    background-color: #f6f8fa;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08);
    transform: translateY(-3px);
  }
  &.is_active {
    color: #ff6a00;
    background-color: #f6f8fa;
    .dropdown_headline {
      color: #ff6a00;
    }
  }
  .dropdown_nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 12px;
    .dropdown_icon {
      width: 38px;
      height: 38px;
      display: block;
    }
  }
  .dropdown_headline {
    font-size: 18px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei, serif;
    font-weight: 400;
    color: #333;
  }
  .dropdown_subtitle {
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #888;
    margin: 6px 0 0;
  }
}
</style>
