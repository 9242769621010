<template>
  <div style="background-color: #f6f8fa; padding: 120px 0" class="mc">
    <h2 class="text-center font-bold">注册入驻享N大权益</h2>
    <p style="margin-top: 30px; margin-bottom: 60px" class="texts">
      轻松快速的开启社交电商
    </p>
    <div class="contWidth1440 marginAuto display">
      <div
        v-for="(item, index) in list"
        :key="'funlist1' + index"
        :xl="6"
        :lg="6"
        :span="24"
      >
        <div class="fun-text">
          <img :src="item.img" />
          <div class="fun_title" style="color: #333">
            <em>{{ item.em }}</em
            >{{ item.title }}
          </div>
          <ul class="list">
            <li
              v-for="(text, index) in item.ulList"
              :key="'funlistUl1' + index"
              class="listItem"
            >
              {{ text }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="contWidth1440 marginAuto display" style="margin-top: 40px">
      <div v-for="(item, index) in list2" :key="'funlist2' + index">
        <div class="fun-text">
          <img style="width: 70px" :src="item.img" />
          <div class="fun_title" style="color: #333">
            <em>{{ item.em }}</em
            >{{ item.title }}
          </div>
          <ul class="list">
            <li
              v-for="(text, index) in item.ulList"
              :key="'funlistUl2' + index"
              class="listItem"
            >
              {{ text }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const list = [
  {
    img: "/images/home/funlist1.png",
    ulList: [
      "简单注册，5步快速建站",
      "SAAS模式，无需安装部署服务器",
    ],
    em: "01",
    title: "智慧建站",
  },
  {
    img: "/images/home/funlist2.png",
    ulList: [
      "国内各大自媒体平台战略合作",
      "营销流量扶持，建立专属私域电商",
    ],
    em: "02",
    title: "大型直播流量扶持",
  },
  {
    img: "/images/home/funlist3.png",
    ulList: [
      "专业级私域运营经理，细致培训护航",
      "帮助用户快速实现运营闭环",
    ],
    em: "03",
    title: "卖家扶持计划",
  },
];
const list2 = [
  {
    img: "/images/home/funlist4.png",
    ulList: [
      "二级分销功能插件，裂变式获客引流",
      "社交领域流量汇聚收入囊中",
    ],
    em: "04",
    title: "二级分销",
  },
  {
    img: "/images/home/funlist5.png",
    ulList: [
      "爆品数据推荐、商城用户数据全面记录",
      "及时直观的营销活动效果，先行一步",
    ],
    em: "05",
    title: "数据分析",
  },
  {
    img: "/images/home/funlist6.png",
    ulList: [
      "5G消息营销推广，海量精准用户数据",
      "定制营销推广，实时数据，效果看得见",
    ],
    em: "06",
    title: "定制推广营销服务",
  },
];
export default {
  name: "FunctionList",
  data() {
    return {
      list: list,
      list2: list2,
    };
  },
};
</script>

<style scoped lang="scss">
.mc {
  background: url("/mp/VirHome/mc.png") no-repeat;
  background-size: 100% 100%;
}
em {
  font-size: 48px;
  color: #333333;
  font-weight: 400;
  font-family: Montserrat;
  margin-right: 10px;
}
img {
  max-width: 60px;
  max-height: 60px;
  width: 100%;
  margin: 0 auto;
}
.fun_title {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 800;
  text-align: center;
  margin: 30px 0;
}
.fun-text {
  width: 440px;
  padding: 50px;
  background-color: #fff;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.05);
  border-radius: 15px 15px 15px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  text-align: left;
}
ul {
  font-size: 18px;
}
ul.list > li:before {
  content: "";
  display: inline-block;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background-color: #ff4800;
  margin-right: 15px;
}
</style>
