/**
 * @author  XuHongli
 * @date  2022/12/22 15:06
 * @version 1.0
 * @description 头部配置
 */

const HeaderConfig = [{
  name: '首页',
  path: '/',
}, {
  name: '万鲸供应链',
  path: 'https://wanjingsc.com/'
}, {
  name: '产品服务',
  more: true,
  // path: '/virHome'
}, {
  //   name: '开放平台',
  //   path: '/helpCenter'
  // },{
  name: '企业动态',
  path: '/news'
},
//   , {
//   name: '关于我们',
//   path: '/aboutUs'
// },
{
  name: '关于我们',
  path: '/ContactUs'
}]


export default HeaderConfig
