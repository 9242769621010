/**
 * @author  XuHongli
 * @date  2022/12/30 16:40
 * @version 1.0
 * @description
 */
import axios from 'axios'

const service = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? '/api' : '',
  timeout: 300000 // 请求超时时间 ms 5分钟
})
/** 超时时间 */
service.defaults.timeout = 300000

// 返回其他状态码
service.defaults.validateStatus = function(status) {
  return status // 默认的
}

/** 跨域请求，允许保存cookie */
service.defaults.withCredentials = true

/** HTTP request interceptors HTTP请求拦截 */
service.interceptors.request.use(config => {
  return config
}, error => {
  return Promise.reject(error)
})
/** HTTP response interceptors HTTP响应拦截 */
service.interceptors.response.use(res => {
  return res.data
}, error => {
  return Promise.reject(new Error(error))
})


export default service
