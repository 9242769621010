<template>
  <div class="home">
    <div class="modal_box">
      <div class="leftIcon">
        <a-icon type="left" style="font-size: 25px" @click="handlePrev" />
      </div>
      <!-- 走马灯 -->
      <!-- 一定要注意这里指定的 ref -->

      <div class="carou" style="height: 700px">
        <a-carousel autoplay ref="img">
          <div class="home-banner-item">
            <img
              style="height: 700px"
              src="/mp/BannerImage/home_banner1.png"
              alt=""
            />
            <div class="banner1-btn container">
              <div class="text-xl text-gray-1">定制推广营销服务</div>
              <h1 class="text-gray-1 mt-30 mb-50">
                懂私域运营的服务商<br />
                无货源开店 快速启动社交电商
              </h1>
              <a-button type="primary" @click="open"> 请求报价 </a-button>
              <a-button type="info" @click="open"> 了解详情 </a-button>
            </div>
          </div>
          <div class="home-banner-item">
            <div class="banner1-btn container texts">
              <h1 style="color: #fff" class="mt-30 mb-50">
                万鲸深耕新零售领域，赋能众多企业客户<br />
                深挖全域业绩增长
              </h1>
              <p style="color: #fff">
                把粉丝变成终身客户，为卖家与消费者建立可持续的客户关系
              </p>
            </div>
            <img
              style="height: 700px"
              src="/mp/BannerImage/home_banner2.png"
              alt=""
            />
          </div>
        </a-carousel>
      </div>

      <!-- 下一页图标 -->
      <div class="rightIcon">
        <a-icon type="right" style="font-size: 25px" @click="handleNext" />
      </div>
    </div>

    <div class="container">
      <h1 class="mt-120 text-center mb-60">万鲸云能够为您做些什么？</h1>
      <div class="flex-row flex-row-between">
        <div class="home-card" v-for="(item, index) in cardData" :key="index">
          <div class="card-top">
            <div
              v-for="(title, tKey) in item.title"
              :key="`${cardData}-${tKey}`"
            >
              {{ title }}
            </div>
            <div class="num">{{ item.index }}</div>
            <img src="/mp/icon/S.png" alt="" class="s" />
          </div>
          <div style="padding: 0 30px">
            <hr color="#E8E9E9" style="margin: 0; color: #e8e9e9" />
          </div>
          <div style="padding: 0 32px" class="mt-35 text-lg">
            {{ item.desc }}
          </div>
          <a href="" class="card-link text-lg flex-row flex-col-center">
            了解更多
            <img
              src="/mp/icon/arrow.png"
              alt=""
              style="width: 20px; height: 20px"
            />
          </a>
        </div>
      </div>
    </div>
    <div
      style="position: relative; background: #f6f8fa; padding-bottom: 120px"
      class="mt-120"
    >
      <div
        class="container flex-row containersHeghit"
        style="flex-direction: row-reverse"
      >
        <div class="container_leftImg"></div>
        <div style="margin-top: 140px; width: 380px; margin-bottom: 0px">
          <h1>轻松5步操作，30分钟搭建商城</h1>
          <ul class="step-ul mt-30">
            <li>
              <img src="/mp/icon/right.png" alt="" />提供多行业精美模板免费使用
            </li>
            <li><img src="/mp/icon/right.png" alt="" />支持首页可视DIY</li>
            <li><img src="/mp/icon/right.png" alt="" />多种支付方式任意配置</li>
            <li>
              <img
                src="/mp/icon/right.png"
                alt=""
              />阿里云服务保障您的商城稳定流畅
            </li>
          </ul>
          <a-button
            type="primary"
            class="flex-row flex-row-center flex-col-center"
            @click="open"
            style="
              width: 228px;
              height: 58px;
              margin-top: 60px;
              font-size: 18px;
            "
          >
            立刻查看 <img src="/mp/icon/white-arrow.png" alt="" class="ml-10" />
          </a-button>
        </div>
        <div class="container_rightImg"></div>
      </div>
    </div>
    <div
      type="flex"
      justify="center"
      style="background: #f6f8fa; padding-top: 0px; padding-bottom: 120px"
    >
      <div class="contWidth1440 marginAuto">
        <carousel
          class="home_carousel"
          :items="5"
          :autoplay="true"
          :smart-speed="500"
          :nav="false"
        >
          <div
            v-for="(item, index) in tplModel"
            :key="'tplModel' + index"
            style="margin: 0px"
            class="tplBox"
          >
            <img class="modelImg" :src="item" alt="" />
          </div>
        </carousel>
      </div>
    </div>
    <h1 class="text-center mt-120">全方位各类渠道支持</h1>
    <ArticleTpl :data="tplData2" style="margin-top: 60px">
      <template #title>
        <h2>
          <span style="color: #ff6a00">全渠道</span
          >获客，<br />快速把流量转化为自己的客户
          <!--          独立站<span>营销</span>方式-->
          <!--          <br>-->
          <!--          帮您对接全网解决方案-->
        </h2>
      </template>
      <template v-slot:ad>
        <a-button
          type="primary"
          class="flex-row flex-row-center flex-col-center"
          @click="open"
          style="width: 228px; height: 58px; margin-top: 60px; font-size: 18px"
        >
          立刻查看 <img src="/mp/icon/white-arrow.png" alt="" class="ml-10" />
        </a-button>
      </template>
    </ArticleTpl>
    <ArticleTpl
      :reverse="true"
      :data="tplData3"
      :map-bg="true"
      style="margin-top: 80px"
    >
      <template #title>
        <h2>
          <span style="color: #ff6a00">千万级</span>SKU供应链
          <br />
          提供全品类选品，一键上架销售
        </h2>
      </template>
      <template v-slot:ad>
        <a-button
          type="primary"
          class="flex-row flex-row-center flex-col-center"
          @click="open"
          style="width: 228px; height: 58px; margin-top: 60px; font-size: 18px"
        >
          立刻查看 <img src="/mp/icon/white-arrow.png" alt="" class="ml-10" />
        </a-button>
      </template>
    </ArticleTpl>
    <ArticleTpl :data="tplData4" style="margin-top: 80px">
      <template #title>
        <h2>
          丰富的营销玩法，<br />提升<span style="color: #ff6a00">复购率</span>
          <!--          <span>本地化</span>特色功能-->
          <!--          <br>-->
          <!--          应对购物习惯差异-->
        </h2>
      </template>
      <template v-slot:ad>
        <a-button
          type="primary"
          class="flex-row flex-row-center flex-col-center"
          @click="open"
          style="width: 228px; height: 58px; margin-top: 60px; font-size: 18px"
        >
          立刻查看 <img src="/mp/icon/white-arrow.png" alt="" class="ml-10" />
        </a-button>
      </template>
    </ArticleTpl>
    <div class="home-comment mt-120">
      <div class="bgMeng align-items">
        <div class="contWidth1440 marginAuto">
          <carousel
            class="carousels"
            :items="2"
            :autoplay="true"
            :smart-speed="500"
            :nav="false"
          >
            <div
              v-for="(item, index) in listdata"
              :key="'listdata' + index"
              style="margin: 0 20px"
              class="listdata"
            >
              <div>
                <div class="tps">
                  <div class="fuhao"></div>
                  <div class="fuhao1"></div>
                </div>

                <h3>{{ item.title }}</h3>
                <div style="margin-top: 21px; margin-bottom: 30px">
                  <p>{{ item.cont }}</p>
                </div>
                <div class="align-items">
                  <img
                    style="width: 66px; height: 66px"
                    :src="item.img"
                    alt=""
                  />
                  <div style="margin-left: 25px">
                    <div>{{ item.name }}</div>
                    <div style="margin-top: 8px">{{ item.txt }}</div>
                  </div>
                </div>
              </div>
            </div>
          </carousel>
        </div>
      </div>
    </div>
    <!-- <h2 class="text-center mt-120">国际物流+海外仓</h2>
    <p
      style="
        text-align: center;
        margin-top: 33px;
        font-size: 18px;
        font-family: 'Microsoft YaHei', serif;
      "
    >
      一线国际物流战略合作，海外仓部署覆盖全球，打造专业的物流解决方案，即省钱又省时！
    </p>

    <div class="container">
      <video
        id="video"
        src="/map.mp4"
        muted
        playsinline
        autoplay
        loop
        style="max-width: 1440px; width: 100%; height: auto; margin: 0 auto"
      />
    </div> -->
    <FunctionList></FunctionList>
    <h2 class="mt-120 text-center">合作品牌</h2>
    <div class="texts"><p>从开店、运营到引流，为您提供一站式解决方案</p></div>
    <section class="brand-section">
      <div class="container" style="padding-bottom: 120px">
        <carousel
          :items="5"
          :loop="true"
          :autoplay="true"
          :margin="22"
          :autoplay-hover-pause="true"
          :dots="false"
          :nav="false"
          :smart-speed="500"
          slide-by="page"
          :responsive="{
            0: {
              items: 2,
            },
            480: {
              items: 2,
            },
            576: {
              items: 3,
            },
            768: {
              items: 3,
            },
            960: {
              items: 4,
            },
            1200: {
              items: 5,
            },
          }"
          class="brand-slider"
        >
          <div v-for="item in brandList" :key="item" class="item">
            <img :src="`/mp/VirHome/${item}.jpg`" alt="img" />
          </div>
        </carousel>
      </div>
    </section>
    <HomeFooterBanner :title="titles"></HomeFooterBanner>
  </div>
</template>

<script>
import ArticleTitle from "@/components/ArticleTitle";
import ArticleTpl from "@/components/ArticleTpl";
import carousel from "vue-owl-carousel";
import CustomCarousel from "@/views/Home/customCarousel/index.vue";
import HomeFooterBanner from "@/Layouts/components/FooterBanner/footerBanner/index.vue";

import {
  tplModel,
  cardData,
  tplData2,
  tplData3,
  tplData4,
  listdata,
} from "@/views/Home/HomeData";
import FunctionList from "@/views/Home/functionList/index.vue";
import HomeShopLine from "@/views/Home/HomeShopLine/index.vue";

/**
 * @author  XuHongli
 * @date  2022/12/22 12:00
 * @version 1.0
 * @description
 */
export default {
  name: "Home",
  components: {
    HomeShopLine,
    ArticleTpl,
    ArticleTitle,
    carousel,
    CustomCarousel,
    FunctionList,
    HomeFooterBanner,
  },
  data() {
    return {
      cardData: Object.freeze(cardData),
      tplData2: Object.freeze(tplData2),
      tplData3: Object.freeze(tplData3),
      tplData4: Object.freeze(tplData4),
      tplModel: Object.freeze(tplModel),
      brandList: 22,
      listdata: Object.freeze(listdata),
      titles: {
        a: "即刻加入我们的行列，开启私域电商掘金之旅",
        b: "专业技术售后、客户成功顾问、专业服务团队、营销增长专家，助力业务指数级增长不是梦",
      },
    };
  },
  mounted() {
    // for (var i = 0; i < 23; i++) {
    //   this.brandList.push(`/mp/VirHome/${i}.jpg`);
    // }
  },
  methods: {
    open() {
      window.open(process.env.VUE_APP_SET_URL, "_blank");
    },
    handlePrev() {
      // 通过上边指定的ref 来操作
      this.$refs.img.prev();
    },
    handleNext() {
      this.$refs.img.next();
    },
  },
};
</script>
<style lang="scss" scoped>
.modal_box {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;

  .rightIcon {
    width: 50px;
    height: 50px;
    color: #fff;
    line-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.4;
    position: absolute;
    right: 25px;
  }
}
.rightIcon:hover {
  background: #bdbbba;
  border-radius: 50%;
  opacity: 0.5;
}
.carou {
}
.leftIcon {
  width: 50px;
  height: 50px;
  color: #fff;
  line-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 25px;
  opacity: 0.5;
  z-index: 9000;
}
.leftIcon:hover {
  opacity: 0.5;
  background: #bdbbba;
  border-radius: 50%;
}
.ant-carousel {
  width: 100%;
  position: absolute;
  height: 800px;
  top: 0px;
  left: 0;
}

.ant-carousel >>> .custom-slick-arrow:hover {
  opacity: 0.5;
}

.tps {
  position: relative;
}

.fuhao {
  position: absolute;
  background-image: url(/images/home/fu1.png);
  background-repeat: no-repeat;
  background-size: 28px 18px;
  left: -35px;
  top: 0;
}

.carousels {
}
.listdata {
  background: #fff;
  padding: 80px;
  height: 425px;
}
.bgMeng {
  background: url(/images/home/bg.png) no-repeat;
  background-size: 100% 100%;
  height: 835px;
  width: 100%;
}
.home-card {
  position: relative;
  width: 334px;
  height: 372px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.11);
  border-radius: 10px 10px 10px 10px;
  .card-top {
    border-radius: 10px 10px 0px 0px;
    position: relative;
    width: 334px;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 131px;
    padding: 32px;
    color: #000;
    font-weight: 600;
    font-size: 22px;
    transition: all 0.2s;
    .num {
      font-size: 48px;
      font-family: Montserrat;
      position: absolute;
      color: rgba(153, 153, 153, 0.18);
      top: 50%;
      right: 30px;
      transform: translateY(-50%);
    }
    .s {
      transition: all 0.3s;
      position: absolute;
      top: 50%;
      right: 70.7px;
      transform: translateY(-50%);
      opacity: 0;
    }
  }
  .card-link {
    position: absolute;
    color: #ff6a00;
    bottom: 44px;
    left: 34px;
  }
  &:hover {
    .card-top {
      transition: all 0.2s;
      color: #fff;
      background: linear-gradient(306deg, #ff9f4f 0%, #ff7e0e 100%);
      .s {
        opacity: 1;
      }
    }
    hr {
      opacity: 0;
    }
  }
}
.home-banner-item {
  height: auto;
  width: 100%;
  position: relative;
  img {
    width: 100%;
    height: auto;
  }
  .banner1-btn {
    position: absolute;
    top: 22.6%;
    left: 50%;
    transform: translateX(-50%);
    .ant-btn {
      font-size: 18px;
      width: 181px;
      height: 54px;
      margin-right: 18px;
    }
  }
}
.home-comment {
  position: relative;
  max-width: 100%;
  overflow: hidden;
  .comment-img {
    position: absolute;
    top: 50%;
    right: 10%;
    transform: translateY(-50%);
  }
}
.step-ul {
  li {
    font-size: 18px;
    color: #666666;
    margin-top: 13px;
  }
}
.home_carousel {
  .slick-active > button {
    background-color: #000 !important;
  }
}
.ant-carousel .slick-dots li button {
  background-color: #333;
}

.tplTitle {
  font-family: "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑",
    Arial, sans-serif;
  font-size: 3.6rem;
  font-weight: bolder;
  text-align: left;
  color: #000;
  span {
    font-family: "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei",
      "微软雅黑", Arial, sans-serif;
    color: #ff6a00;
    font-size: 3.6rem;
    font-weight: bolder;
    text-align: left;
  }
}
.tplAD {
  color: #0364ff;
  font-family: "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑",
    Arial, sans-serif;
  font-size: 2rem;
}
.tplAD:after {
  content: "";
  display: inline-block;
  width: 13px;
  height: 13px;
  margin-top: 32px;
  margin-left: 20.5px;
  transform: rotate(45deg);
  border-top: 2.1px solid #0364ff;
  border-right: 2.1px solid #0364ff;
}
</style>

<style lang="scss" scoped>
.banner-col {
  overflow: hidden;
  background-color: #00307d;
  height: 650px;
}
@media only screen and (max-width: 992px) {
  .banner-col {
    height: unset;
  }
}
.home_text {
  text-align: left;
  div.title h2 {
    font-size: 6.4rem;
    color: #fff;
  }
  div.title h2 .org {
    color: #ffa200;
  }
  div.title p {
    color: white;
    font-size: 2.4rem;
    margin-top: 31px;
  }
  background-color: #00307d;
  width: 100%;
  height: 650px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (min-width: 0px) and (max-width: 576px) {
  .home_text {
    text-align: left;
    div.title h2 {
      font-size: 5rem;
      color: #fff;
    }
    div.title h2 .org {
      color: #ffa200;
    }
    div.title p {
      color: white;
      font-size: 1.8rem;
      margin-top: 31px;
    }
    background-color: #00307d;
    width: 100%;
    height: 440px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.a_white {
  color: white !important;
  &:after {
    border-top-color: white !important;
    border-right-color: white !important;
  }
}
.title-word:before {
  content: " ";
  display: inline-block;
  width: 111px;
  height: 8px;
  background: url("/images/title_left_white.png") no-repeat;
  margin-right: 10%;
  background-size: 100%;
}
.title-word:after {
  content: " ";
  display: inline-block;
  width: 111px;
  height: 8px;
  background: url("/images/title_right_white.png") no-repeat;
  margin-left: 10%;
  background-size: 100%;
}
.title-word {
  font-family: "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑",
    Arial, sans-serif;
  font-size: 3.66rem;
  font-weight: bolder;
  text-align: center;
  vertical-align: bottom;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.modelImg {
  max-width: 264px;
  max-height: 527px;
  width: 100%;
}
::v-deep .swiper-wrapper {
  display: flex;
}
.recommendPage2 {
  position: relative;
  width: 100%;
}
.containersHeghit {
  margin: 200px 0;
  padding: 130px 0;
}
.container_leftImg {
  background: url(/mp/img/homeStep.png) no-repeat;
  background-position: left;
  height: 722px;
  width: 1080px;
  background-size: 100%;
  position: absolute;
  left: 0;
  top: 120px;
}
.container_rightImg {
  background: url(../../assets/home/mengban.png);
  background-position: right;
  position: absolute;
  right: 0;
  width: 630px;
  height: 200px;
  background-size: 100%;
  bottom: 50px;
}
.recommendPage .swiper-container {
  position: relative;
  width: 100%;
  height: 878px;
}

.recommendPage .swiper-container .swiper-slide {
  width: 100%;
  line-height: 200px;
  color: #000;
  font-size: 1.6rem;
  text-align: center;
}
.swiper-wrapper {
  display: flex;
}

.title {
  font-size: $titleSize;
  font-weight: 500;
  color: #141414;
}
.brand {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  height: 577px;
  & .title {
    font-size: $titleSize;
    color: #141414;
    margin-top: 84px;
    margin-bottom: 18px;
  }
  img {
    width: 229.54px;
    height: 123.6px;
  }
  .brand_list {
    display: flex;
  }
}

.brand-section {
  padding: 60px 0;
  background: #fff;
}
.brand-section .owl-stage-outer {
  padding: 20px 0;
}
.brand-section .item {
  background: #fff;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  // height: 100px;
  border: 2px solid #f4f2f9;
  transition: all 0.4s;
}
.brand-section .item img {
  width: 99%;
  height: auto;
  transition: all 0.4s;
}
.brand-section .item:hover,
.brand-section .item:focus {
  box-shadow: 0 10px 20px rgba(205, 196, 219, 0.3);
}
.brand-section .item:hover img,
.brand-section .item:focus img {
  filter: grayscale(0);
}
.brand-section a {
  display: block;
  text-align: center;
  font-size: 1.6rem;
  font-family: "Source Sans Pro", sans-serif;
  color: #313131;
  text-decoration: underline;
  margin-top: 20px;
}
.brand-section a:hover,
.brand-section a:focus {
  text-decoration: underline;
  color: #ff9c1a;
}
@media only screen and (min-width: 0px) and (max-width: 992px) {
  .home_video {
    height: auto;
    width: auto;
    max-width: 100%;
    min-height: unset;
  }
  .title-word .pc {
    display: none;
  }
}
@media only screen and (min-width: 993px) {
  .home_video {
    min-height: 644px;
    height: auto;
  }
  .title-word .mobile {
    display: none;
  }
}
</style>

<!-- 处理图片 -->
<style lang="scss" scoped>
::v-deep .owl-prev {
  background-color: transparent;
  font-size: 22rem;
}
.owl-nav > .owl-next {
  width: 100px;
  height: 100px;
}
//  width:320px-479px
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .try-img {
    margin-top: 25px;
    width: 300px !important;
    height: 100px !important;
  }
}
</style>

<style lang="scss" scoped>
//  width:480px-575px
@media only screen and (min-width: 480px) and (max-width: 575px) {
  .try-img {
    margin-top: 25px;
    width: 450px !important;
    height: 150px !important;
  }
  ::v-deep .imgDiv {
    & .pcImg {
      // height: 26.1rem;
      background-position: 30% 50%;
    }
  }
}
</style>

<style lang="scss" scoped>
@media (min-width: 375px) and (max-width: 720px) {
  ::v-deep .imgDiv .pcImg {
    height: 70rem !important;
  }
}
//  width:576px-767px
@media only screen and (min-width: 576px) and (max-width: 767px) {
  ::v-deep .imgDiv {
    & .pcImg {
      // height: 26.1rem;
      background-position: 30% 50%;
    }
  }
}
</style>

<style lang="scss" scoped>
@media only screen and (min-width: 720px) and (max-width: 768px) {
  ::v-deep .imgDiv {
    & .pcImg {
      // height: 26.1rem;
      height: calc(100vh - 100px) !important;
      background-position: 30% 50%;
    }
  }
}

//  width:768px-991px
@media only screen and (min-width: 768px) and (max-width: 991px) {
  // banner
  ::v-deep .imgDiv {
    & .pcImg {
      // height: 26.1rem;
      height: calc(100vh - 100px) !important;
    }
  }
}

// banner
@media (min-width: 960px) and (max-width: 1280px) {
  ::v-deep .imgDiv {
    & .pcImg {
      // height: 36rem;
      height: calc(100vh - 100px) !important;
    }
  }
}
@media (min-width: 1280px) and (max-width: 1440px) {
  ::v-deep .imgDiv {
    & .pcImg {
      // height: 39.6rem;
      height: calc(100vh - 100px) !important;
    }
  }
}
@media (min-width: 1440px) and (max-width: 1700px) {
  ::v-deep .imgDiv {
    & .pcImg {
      // height: 43.632rem;
      height: calc(100vh - 100px) !important;
      background-position: 39%;
    }
  }
}

@media (min-width: 1700px) {
  ::v-deep .imgDiv {
    & .pcImg {
      // height: 47.3rem;
      height: calc(100vh - 100px) !important;
    }
  }
}
</style>
