import Vue from 'vue'
import App from './App.vue'
import store from './store'

/** Router */
import router from './router/routers'
import './router/index' // permission control



/** wow */
import wow from 'wowjs'
import 'wowjs/css/libs/animate.css'
import 'animate.css'
Vue.prototype.$wow = wow

/** LazyLoad Image  */
import VueLazyload from 'vue-lazyload'
const loadImage = require('@/assets/loading.gif')
Vue.use(VueLazyload, {
  preLoad: 1.3,
  loading: loadImage,
  attempt: 1
})
Vue.prototype.$StaticFileUrl = process.env.VUE_APP_STATIC_FILE_URL

Vue.prototype.$register = function() {
  // const routeData = this.$router.resolve({ path: '/register', query: { registerType: 'register' }})
  window.open(process.env.NODE_ENV == 'production' ? 'https://console.wanjingai.com/' : 'http://cm.aijdan.com', '_blank')
}


/** Ant-Design */
import '@/utils/core/Lazy_use'

/** Layouts */
import DefaultLayout from '@/Layouts/Default/Default.vue'
import LayoutNull from '@/Layouts/Null/layout-null.vue'

Vue.component('layout-default', DefaultLayout)
Vue.component('layout-null', LayoutNull)

Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
