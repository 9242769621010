/**
 * @author  XuHongli
 * @date  2022/12/28 11:02
 * @version 1.0
 * @description
 */
const ServerRouter = [
  {
    path: '/marketing',
    name: 'Marketing',
    meta: {
      title: '营销活动'
    },
    component: () => import('@/views/ProductServer/Marketing')
  },
  {
    path: '/cloudServices',
    name: 'CloudServices',
    meta: {
      title: '云服务'
    },
    component: () => import('@/views/ProductServer/CloudServices')
  },
  {
    path: '/paasCustomized',
    name: 'PaasCustomized',
    meta: {
      title: 'PaaS定制'
    },
    component: () => import('@/views/ProductServer/PaasCustomized')
  },
  {
    path: '/dbAlliance',
    name: 'DbAlliance',
    meta: {
      title: '万鲸联盟'
    },
    component: () => import('@/views/ProductServer/DbAlliance')
  },
  {
    path: '/advertising',
    name: 'Advertising',
    meta: {
      title: '广告投放'
    },
    component: () => import('@/views/ProductServer/Advertising')
  }
]

export default ServerRouter
