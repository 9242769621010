/**
 * @author  XuHongli
 * @date  2022/12/27 16:08
 * @version 1.0
 * @description
 */

const ProductRouter = [
  {
    path: '/virhome',
    name: 'VirHome',
    meta: {
      title: '数字权益'
    },
    component: () => import('@/views/ProductServer/VirHome')
  },
  {
    path: '/advhome',
    name: 'AdvHome',
    meta: {
      title: '数智化广告平台'
    },
    component: () => import('@/views/ProductServer/AdvHome')
  },
  {
    path: '/inthome',
    name: 'Inthome',
    meta: {
      title: '积分商城'
    },
    component: () => import('@/views/ProductServer/Inthome')
  },
  {
    path: '/customerService',
    name: 'CustomerService',
    meta: {
      title: '智能客服'
    },
    component: () => import('@/views/ProductServer/CustomerService')
  },
  {
    path: '/cloudElectricity',
    name: 'CloudElectricity',
    meta: {
      title: 'SAAS云电商'
    },
    component: () => import('@/views/ProductServer/CloudElectricity')
  },
  {
    path: '/onlineLicense',
    name: 'OnlineLicense',
    meta: {
      title: '在线证件照'
    },
    component: () => import('@/views/ProductServer/OnlineLicense')
  },
  {
    path: '/livingService',
    name: 'LivingService',
    meta: {
      title: '生活缴费'
    },
    component: () => import('@/views/ProductServer/LivingService')
  },
  {
    path: '/electronicContract',
    name: 'ElectronicContract',
    meta: {
      title: '电子合同'
    },
    component: () => import('@/views/ProductServer/ElectronicContract')
  },
  {
    path: '/supplyChain',
    name: 'SupplyChain',
    meta: {
      title: '聚合供应链',
    },
    component: () => import('@/views/ProductServer/SupplyChain')
  }
]

export default ProductRouter
