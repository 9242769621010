<template>
  <div class="BackTop">
    <div class="BackRight">
      <div class="BackRight_txt">
        <img
          class="BackRight_img"
          src="../../../assets/home/phone.png"
          alt=""
        />
      </div>
      <div class="BackRight_er">
        <img class="BackRight_img" src="../../../assets/home/wx.png" alt="" />
      </div>
      <div>
        <img
          style="width: 35px; height: 35px"
          @click="solltop"
          class="BackRight_img"
          src="../../../assets/home/dwon.png"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BackTop",
  methods: {
    solltop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style scoped lang="scss">
.BackRight {
  position: fixed;
  right: 10px;
  top: 45%;
  background: #c5c3c3;
  width: 42px;
  z-index: 18000;
  text-align: center;
  border-radius: 25px;
  padding: 8px 0;

  &_img {
    width: 42px;
    height: 42px;
    margin: 2px 0;
    cursor: pointer;
    z-index: 20000;
  }
  &_txt {
    position: relative;
  }
  &_txt:hover:before {
    content: "0755-83899969";
    transition: all 0.9s;
    position: absolute;
    cursor: pointer;
    right: 1px;
    top: 1px;
    background: #ff6a00;
    width: 170px;
    height: 42px;
    line-height: 42px;
    text-align: left;
    color: #fff;
    padding-left: 15px;
    border-radius: 25px;
    background-image: url(../../../assets/home/phone.png);
    background-repeat: no-repeat;
    background-position: right;
    background-size: 42px 42px;
    padding-right: 10px;
  }
  &_er {
    position: relative;
  }
  &_er:hover::before {
    content: " ";
    transition: all 0.9s;
    position: absolute;
    border-radius: 25px;
    right: 1px;
    cursor: pointer;
    top: 1px;
    width: 120px;
    height: 42px;
    background: #ff6a00;
    display: inline-block;
    background-image: url(../../../assets/home/wx.png);
    background-repeat: no-repeat;
    background-position: right;
    background-size: 42px 42px;
  }
  &_er:hover::after {
    content: " ";
    position: absolute;
    background: #ff6a00;
    display: inline-block;
    top: 1px;
    left: -90px;
    border-radius: 5px;
    height: 90px;
    width: 90px;
    background-image: url(../../../assets/home/er.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 80px 80px;
  }
}
</style>
