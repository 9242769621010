/**
 * @author  XuHongli
 * @date  2022/12/28 15:48
 * @version 1.0
 * @description
 */
const SolutionHoverList = [
  {
    title: '银行服务解决方案',
    subTitle: '银行全场景化一站式服务',
    icon: process.env.VUE_APP_STATIC_FILE_URL + '/mp/ProductServer/cp_yh_img_02.png',
    path: '/banking',
  },
  {
    title: '保险行业解决方案',
    subTitle: '保险全场景数字化服务',
    icon: process.env.VUE_APP_STATIC_FILE_URL + '/mp/ProductServer/cp_bx_img_05.png',
    path: '/insurance',
  },
  {
    title: '政企服务解决方案',
    subTitle: '政企全场景数字化服务',
    icon: process.env.VUE_APP_STATIC_FILE_URL + '/mp/ProductServer/Government_enterprise_3.png',
    path: '/politics',
  },
  {
    title: '企业采购解决方案',
    subTitle: '企业内部采购解决方案',
    icon: process.env.VUE_APP_STATIC_FILE_URL + '/mp/ProductServer/head_jiejue_caigou_2.png',
    path: '/retailers',
  },
  {
    title: '新零售行业解决方案',
    subTitle: '新零售数字化运营服务',
    icon: process.env.VUE_APP_STATIC_FILE_URL + '/mp/ProductServer/newRetail-icon_2.png',
    path: '/newRetail',
  },
  {
    title: '企业福利解决方案',
    subTitle: '企业弹性福利采购服务',
    icon: process.env.VUE_APP_STATIC_FILE_URL + '/mp/ProductServer/head_jiejue_fuli_2.png',
    path: '/sellRetail',
  },
  {
    title: '本地化服务解决方案',
    subTitle: '本地化服务流量运营',
    icon: process.env.VUE_APP_STATIC_FILE_URL + '/mp/ProductServer/localized_service_logo1_2.png',
    path: '/localizedService',
  },
  {
    title: '企业费控解决方案',
    subTitle: '企业费用控制管理平台',
    icon: process.env.VUE_APP_STATIC_FILE_URL + '/mp/ProductServer/Enterprise_isc_02.png',
    path: '/enterprise',
  },
]

export default SolutionHoverList
