import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home/index'
import ProductRouter from '@/router/ProductRouter'
import SolutionRouter from '@/router/SolutionRouter'
import ServerRouter from '@/router/ServerRouter'
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

/**
 * 默认路由表
 */
export const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      title: '万鲸云-企业数字化服务平台，懂私域运营的服务商'
    },
    component: Home
  },
  {
    path: '/aboutUs',
    name: 'AboutUs',
    meta: {
      title: '关于我们'
    },
    component: () => import('@/views/AboutUs')
  },
  {
    path: '/joinUs',
    name: 'JoinUs',
    meta: {
      title: '加入我们'
    },
    component: () => import('@/views/Protocol/JoinUs')
  },
  {
    path: '/privacy',
    name: 'Privacy',
    meta: {
      title: '隐私政策'
    },
    component: () => import('@/views/Protocol/Privacy')
  },
  {
    path: '/agreement',
    name: 'Agreement',
    meta: {
      title: '用户服务协议'
    },
    component: () => import('@/views/Protocol/Agreement')
  },
  {
    path: '/ContactUs',
    name: 'ContactUs',
    meta: {
      title: '联系我们'
    },
    component: () => import('@/views/ContactUs/index')
  },
  {
    path: '/News',
    name: 'News',
    meta: {
      title: '企业动态'
    },
    component: () => import('@/views/News')
  },
  {
    path: '/NewsInfo',
    name: 'NewsInfo',
    meta: {
      title: '企业动态',
    },
    component: () => import('@/views/News/NewsInfo')
  },
  {
    path: '/channel',
    name: 'Channel',
    meta: {
      title: '渠道合作'
    },
    component: () => import('@/views/Channel')
  },
  {
    path: '/invite',
    name: 'Invite',
    meta: {
      title: '招商入驻'
    },
    component: () => import('@/views/Invite')
  },
  {
    path: '/helpCenter',
    name: 'HelpCenter',
    meta: {
      title: '帮助中心'
    },
    component: () => import('@/views/HelpCenter')
  },
  {
    path: '/documentcenter',
    name: 'DocumentCenter',
    meta: {
      title: '开发平台详情'
    },
    component: () => import('@/views/DocumentCenter')
  },
  {
    path: '/oversea',
    name: 'Oversea',
    meta: {
      title: ' 低成本出海'
    },
    component: () => import('@/views/Oversea')
  },
  {
    path: '/liveSales',
    name: 'livesSales',
    meta: {
      title: '社交媒体直播'
    },
    component: () => import('@/views/livesSales')
  }
]

export const NotFoundRouters = [
  {
    path: '/404',
    name: 'NotFound',
    meta: {
      title: 'NotFound',
      layout: 'null'
    },
    component: () => import('@/views/Error/404'),
    hidden: true
  },
  {
    path: '*',
    redirect: '/404'
  }
]

const router = new VueRouter({
  mode: 'history',
  routes: [...routes, ...NotFoundRouters, ...ProductRouter, ...SolutionRouter, ...ServerRouter],
  scrollBehavior: () => ({ y: 0 })
})

export default router
