<!-- home 底部宣传 banner -->
<template>
  <section class="footer-banner">
    <div class="">
      <h1
        v-if="title"
        style="font-size: 3.66rem; color: #fff; font-weight: 800"
      >
        {{ title.a }}
      </h1>
      <h1 v-else style="font-size: 3.66rem; color: #fff; font-weight: 800">
        免费试用万鲸云，立刻体验
      </h1>
      <span v-if="title" class="tplAD a_white">
        {{ title.b }}
      </span>
      <span v-else class="tplAD a_white">
        全渠道 获客，快速把流量转化为自己的客户
      </span>
    </div>
    <div class="flex-row flex-row-center">
      <a-button type="info" @click="open" class="footer-btn mt-60">
        注册体验<img src="/mp/icon/arrow.png" style="width: 25px" />
      </a-button>
    </div>
  </section>
</template>

<script>
export default {
  name: "HomeFooterBanner",
  props: {
    title: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    console.log("运行环境", process.env.VUE_APP_SET_URL);
  },
  methods: {
    open() {
      window.open(process.env.VUE_APP_SET_URL, "_blank");
    },
  },
};
</script>

<style scoped lang="scss">
.a_white {
  color: white !important;
  &:after {
    border-top-color: white !important;
    border-right-color: white !important;
  }
}
.footer-btn {
  width: 173px;
  height: 53px;
  display: flex;
  align-items: center;
  font-size: 18px;
  justify-content: center;
  color: $color-primary;
}
.tplAD {
  color: #0364ff;
  font-family: "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑",
    Arial, sans-serif;
  font-size: 18px;
}
.footer-banner {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 510px;
  background-image: url("/images/bg2.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  background-attachment: fixed;
}
</style>
