<template>
  <div
    class="SolutionHover"
    v-show="value"
    @mouseenter="emitEnter"
    @mouseleave="emitLeave"
  >
    <div class="dropdown_left">
      <div class="dropdown_left_small">
        <a
          v-for="(item, index) in SolutionHoverList"
          :key="index"
          :href="item.path"
          :class="['dropdown_li', { is_active: $route.path === item.path }]"
          ><span class="dropdown_nav"
            ><img :src="item.icon" class="dropdown_icon" alt=""
          /></span>
          <div>
            <div class="dropdown_headline">{ { item.title } }</div>
            <div class="dropdown_subtitle">{ { item.subTitle } }</div>
          </div></a
        >
      </div>
    </div>
  </div>
</template><script>
import SolutionHoverList from "@/Layouts/components/SolutionHover/SolutionHoverList";

/**
 * @author  XuHongli
 * @date  2022/12/28 16:00
 * @version 1.0
 * @description
 */
export default {
  name: "SolutionHover",
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      SolutionHoverList,
    };
  },

  methods: {
    emitEnter() {
      this.$emit("input", true);
    },

    emitLeave() {
      this.$emit("input", false);
    },
  },
};
</script><style scoped lang="scss">
.SolutionHover {
  width: 534px;
  left: 30%;
  padding: 30px 20px;
  background: #fff;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08);
  position: absolute;
  top: 62px;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  z-index: 99999;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
}

.dropdown_left {
  width: 625px;

  .dropdown_left_title {
    height: 30px;
    font-size: 18px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN, serif;
    font-weight: 400;
    color: #555;
    line-height: 10px;
    border-bottom: 1px solid #eee;
    margin-bottom: 15px;
  }

  .dropdown_left_small {
    display: flex;
    flex-wrap: wrap;
  }
}

.dropdown_li {
  width: 234px;
  height: 68px;
  padding: 18px 0 18px 18px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN, serif;
  margin-bottom: 10px;
  transition: all 0.5s;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin-right: 10px;

  &:nth-child(odd) {
    margin-right: 18px;
  }

  &:hover {
    transition: all 0.4s ease;
    background-color: #f6f8fa;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08);
    transform: translateY(-3px);
  }

  &.is_active {
    color: #ff6a00;
    background-color: #f6f8fa;

    .dropdown_headline {
      color: #ff6a00;
    }
  }

  .dropdown_nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 12px;

    .dropdown_icon {
      width: 38px;
      height: 38px;
      display: block;
    }
  }

  .dropdown_headline {
    font-size: 18px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei, serif;
    font-weight: 400;
    color: #333;
  }

  .dropdown_subtitle {
    font-size: 12px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei, serif;
    font-weight: 400;
    color: #888;
    margin: 6px 0 0;
  }
}
</style>