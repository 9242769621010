<template>
  <div class="footer-default">
    <div class="container flex-row">
      <div class="footer-left1">
        <img src="/mp/footer/footer-logo.png" alt="" style="margin-top: 80px" />
        <div class="text-md" style="margin-top: 41px">全国统一服务热线</div>
        <div class="text-xl">0755-83899969</div>
        <div class="text-md" style="margin-top: 30px">公司地址</div>
        <div class="text-md" style="font-size: 16px">
          广东省深圳市南山区北京航空航天大厦1座7003
        </div>
      </div>
      <div class="footer-left2" style="margin-left: 129px">
        <ul class="nav" style="margin-top: 120px">
          <div style="font-size: 20px" class="font-bold">服务氛围</div>
          <li>
            <a href="">首页</a>
          </li>
          <!-- <li>
            <a href="">功能介绍</a>
          </li>
          <li>
            <a href="">定价方案</a>
          </li>
          <li>
            <a href="">海外推广</a>
          </li>
          <li>
            <a href="">联系我们</a>
          </li> -->
          <li>
            <a href="https://wanjingsc.com/">万鲸供应链</a>
          </li>
          <li>
            <a href="/virhome">产品服务</a>
          </li>
          <li>
            <a href="/news">企业动态</a>
          </li>
          <li>
            <a href="/contactUs">联系我们</a>
          </li>
        </ul>
      </div>
      <div class="footer-left3" style="margin-left: 147px">
        <ul class="nav" style="margin-top: 120px">
          <div style="font-size: 20px" class="font-bold">技术支持</div>
          <li>
            <a href="/virhome">数字权益</a>
          </li>
          <li>
            <a href="/inthome">积分商城</a>
          </li>
          <li>
            <a href="/oversea">一件代发</a>
          </li>
          <li>
            <a href="/liveSales">媒体直播</a>
          </li>
          <li>
            <a href="/contactUs">关于我们</a>
          </li>
        </ul>
      </div>
      <div class="footer-left4" style="margin-left: 147px">
        <div
          style="font-size: 20px; margin-top: 120px; margin-bottom: 31px"
          class="font-bold"
        >
          获取最新资讯
        </div>
        <form class="form">
          <input
            type="text"
            placeholder="请输入电子邮箱"
            style="color: #000"
            required
            v-model="email"
          />
          <button @click="save">订阅</button>
        </form>
        <div
          class="text-md"
          style="color: #999; margin-top: 24px; font-size: 14px"
        >
          <span>* 请留下邮箱以便获取我们所有最新资讯与动态</span>
        </div>
      </div>
    </div>
    <div class="container" style="margin-top: 54px">
      <hr color="#5a5a5b" style="color: #5a5a5b; margin: 0 0 20px" />
      <div class="flex-row">
        <div
          class="h-full flex-row flex-col-center text-md"
          style="color: #999; font-size: 14px"
        >
          <span>Copyright © 2022 深圳市万鲸科技有限公司 </span>
          <span class="mx-5"> | </span>
          <a
            href="https://beian.miit.gov.cn/#/Integrated/index"
            style="color: #999"
            >粤ICP备20008713号</a
          >
          <span class="mx-5"> | </span>
          <a
            href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030402003757"
            style="color: #999"
            >粤公网安备 44030402003757号</a
          >
        </div>
        <div class="ml-auto footer-bottom">
          <a href="/agreement" class="mr-10">用户协议</a>
          <a href="/privacy" class="mr-10">隐私政策</a>
          <!-- <a href="/helpCenter" class="mr-10">规则中心</a> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @author  XuHongli
 * @date  2022/12/22 16:08
 * @version 1.0
 * @description 默认页脚
 */
import FooterConfig from "@/config/FooterConfig";
import { getAdd } from "@/api/HelpCenter";

export default {
  name: "FooterDefault",
  data() {
    return {
      email: "",
      FooterConfig,
    };
  },
  methods: {
    onSearch(value) {
      console.log(value);
    },
    save() {
      if (!this.email) return this.$message.error("请输入您的邮箱");
      getAdd({ email: this.email }).then((res) => {
        if (res.code == 200) {
          this.$message.success("提交成功");
          this.email = "";
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.footer-default {
  background-color: #1b1b1c;
  height: 461px;
  color: #f6f8fa;
}
.nav {
  > div {
    margin-bottom: 15px;
  }
  li {
    width: 79px;
    height: 32px;
    a {
      font-size: 14px;
      color: #f6f8fa;
    }
  }
}
.footer-bottom {
  a {
    color: #999;
  }
}
.form {
  display: flex;
  align-items: center;
  input {
    padding: 0 8px;
    width: 318px;
    height: 60px;
    font-size: 18px;
    padding-left: 25px;
    outline: none;
    border: none;
    border-radius: 5px 0px 0px 5px;
  }
  button {
    display: flex;
    align-items: center;
    height: 60px;
    width: 198px;
    justify-content: center;
    line-height: 60px;
    font-size: 18px;
    background: #ff6a00;
    color: #fff;
    padding: 0 20px;
    border: #ff6a00;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
    .iconfont {
      font-size: 23px;
    }
  }
}
</style>
