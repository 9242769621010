
import NProgress from 'nprogress'
// import Vue from 'vue'
import router, {NotFoundRouters, routes} from '@/router/routers'

NProgress.configure({ showSpinner: false })// NProgress Configuration

const whiteList = [...routes, ...NotFoundRouters].map((item) => {
  return item.path
}) // 路由白名单


router.beforeEach(async (to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }

  next()
  NProgress.start() // start progress bar
})

router.afterEach(() => {
  NProgress.done() // finish progress bar
})

