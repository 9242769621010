<template>
  <a-config-provider>
    <div id="app">
      <component :is="layout">
        <router-view />
      </component>
    </div>
  </a-config-provider>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {
    console.log(process.env);
  },
  computed: {
    locale() {
      // 只是为了切换语言时，更新标题
      return "zh-CN";
    },
    // Sets components name based on current route's specified layout, defaults to
    // <layout-default></layout-default> component.
    layout() {
      return "layout-" + (this.$route.meta.layout || "default").toLowerCase();
    },
  },
};
</script>

<style lang="scss">
body {
  background: #fff;
}
// todos
html {
  min-width: 1440px;
}
.top20 {
  margin-top: 20px;
}
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>
