<template>
  <div>
    <!-- 底部 -->
    <div class="footer-container">
      <div class="footer-content">
        <a-row :gutter="[24, 24]" type="flex">
          <a-col
            :span="12"
            :xs="24"
            :sm="24"
            :md="12"
            :lg="6"
            :xl="6"
            class="card"
          >
            <div class="about">
              <h3>关于我们</h3>
              <p>深圳市万鲸科技有限公司</p>
              <ul>
                <li>
                  <span><i class="iconfont icon-weizhi" /></span>地址：
                  广东省深圳市南山区北京航空航天大厦1座7003
                </li>
                <li>
                  <span><i class="iconfont icon-24gf-telephone" /></span
                  >0755-83899969
                </li>
              </ul>
            </div>
          </a-col>
          <a-col
            :span="12"
            :xs="24"
            :sm="12"
            :md="12"
            :lg="6"
            :xl="6"
            class="card"
          >
            <div>
              <h3>服务</h3>
              <ul>
                <li><a href="/home">首页</a></li>
                <li><a href="/business">功能介绍</a></li>
                <li><a href="/price">定价方案</a></li>
                <li><a href="/support">海外推广</a></li>
                <li><a href="/contactUs">联系我们</a></li>
                <!--                <li><a href="">Consultation</a></li>-->
              </ul>
            </div>
          </a-col>
          <a-col
            :span="12"
            :xs="24"
            :sm="12"
            :md="12"
            :lg="6"
            :xl="6"
            class="card"
          >
            <div>
              <h3>支持</h3>
              <ul>
                <li><a href="/case">案例</a></li>
                <li><a href="/Template">商店模板</a></li>
                <li><a href="/business/module">标准化模块</a></li>
                <li><a href="/business/customise">研发定制</a></li>
                <li><a href="/aboutUs">关于我们</a></li>
              </ul>
            </div>
          </a-col>
          <a-col
            :span="12"
            :xs="24"
            :sm="12"
            :md="12"
            :lg="6"
            :xl="6"
            class="card"
          >
            <div class="consult">
              <h3>获取最新资讯</h3>
              <p>请留下邮箱以便获取我们所有最新资讯与动态</p>
              <form class="form">
                <input
                  type="email"
                  placeholder="请输入电子邮箱"
                  style="color: #000"
                  required
                />
                <button><i class="iconfont icon-fasong" /></button>
              </form>
            </div>
          </a-col>
        </a-row>
      </div>
    </div>

    <!-- 规则 -->
    <div class="copyright">
      <div class="copyright-content">
        <a-row
          :gutter="[24, 24]"
          type="flex"
          align="middle"
          style="margin: 0; min-height: 60px"
        >
          <a-col
            :span="24"
            :xs="24"
            :sm="24"
            :md="8"
            :lg="8"
            style="padding: 0"
          >
            <p>© 2022 深圳市万鲸科技有限公司 版权所有</p>
          </a-col>
          <a-col
            :span="24"
            :xs="24"
            :sm="24"
            :md="8"
            :lg="8"
            style="padding: 0"
          >
            <p style="text-align: center">
              <a
                href="https://beian.miit.gov.cn/#/Integrated/index"
                target="_blank"
                >粤ICP备20008713号-7</a
              >
            </p>
          </a-col>
          <a-col
            :span="24"
            :xs="24"
            :sm="24"
            :md="8"
            :lg="8"
            style="padding: 0"
          >
            <div class="link">
              <a href="/treaty/contract" target="_blank">平台合同</a>
              <a href="/treaty/userAgreement" target="_blank">用户协议</a>
              <a href="/treaty/privacyPolicy" target="_blank">隐私政策</a>
              <a href="/ruleCenter" target="_blank"> 规则中心 </a>
            </div>
          </a-col>
        </a-row>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @author  XuHongli
 * @date  2023/2/20 10:09
 * @version 1.0
 * @description FooterVll.vue
 */
export default {
  name: "FooterVll",
};
</script>

<style scoped lang="scss">
// 容器
.footer-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #fff;
  width: 100%;
  height: 100%;
  min-height: 550px;
  padding-top: 235px; // 处理那半个压下来的banner + 距离
  background: url(../../../assets/img/footer-bg.jpg) center center no-repeat;
  background-size: cover;
  text-align: left;
  font-family: nunito, "Microsoft YaHei";
}
// 内容
.footer-content {
  width: 90%;
  max-width: 1140px;
  padding-bottom: 30px;
}

h3 {
  font-size: 20px;
  line-height: 100%;
  font-weight: 700;
  color: #fff;
  padding-bottom: 8px;
  margin-top: -2px;
  margin-bottom: 40px;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 66px;
    border: 1px solid #fff;
    border-radius: 1px;
  }
}

.card {
  background-color: transparent;
}

// li的文字
ul li {
  display: flex;
  font-size: 18px;
  line-height: 24px;
  color: #fff;
  a {
    font-size: 18px;
    line-height: 30px;
    color: #fff;
  }
  span {
    font-size: 18px;
    line-height: 30px;
    margin-right: 10px;
    color: #e7b03c;
  }
}

.about {
  li {
    margin-bottom: 11px;
  }
  p {
    font-size: 18px;
    line-height: 26px;
    color: #fff;
    margin-top: -7px;
    margin-bottom: 31px;
  }
}

// 咨询
.consult {
  p {
    font-size: 18px;
    line-height: 26px;
    color: #fff;
    margin-top: -7px;
    margin-bottom: 22px;
  }
}

// 表单列
.form {
  display: flex;
  input {
    padding: 0 8px;
    font-size: 18px;
  }
  button {
    display: flex;
    align-items: center;
    height: 55px;
    line-height: 63px;
    font-size: 28px;
    background: #e7b03c;
    color: #fff;
    padding: 0 20px;
    border: 1px solid #e7b03c;
    border-radius: 0 5px 5px 0;
    .iconfont {
      font-size: 23px;
    }
  }
}

// 版权-链接
.copyright {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .copyright-content {
    align-items: center;
    width: 90%;
    max-width: 1140px;
  }
  background: #201947;
  p,
  a {
    font-size: 18px;
    line-height: 45px;
    color: #ccc;
    margin-bottom: 0;
  }

  .link {
    padding: 10px 0;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: center;
    a {
      font-size: 18px;
      color: #ccc;
      padding: 0 15px;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        top: 0;
        right: -0.5px;
        width: 1px;
        height: 100%;
        background: #ccc;
      }
    }
    a:first-child {
      padding-left: 0;
    }
    a:last-child {
      padding-right: 0;
      &:after {
        display: none;
      }
    }
    a:hover {
      color: #e7b03c;
    }
  }
}
</style>
