/**
 * @author  XuHongli
 * @date  2022/12/28 11:50
 * @version 1.0
 * @description
 */

const ProductHoverList = [
  {
    title: '数字权益',
    subTitle: '提供丰富数字权益服务',
    icon: process.env.VUE_APP_STATIC_FILE_URL + '/mp/ProductServer/head_quanyi.png',
    path: '/virhome',
  },
  {
    title: '积分商城',
    subTitle: 'SaaS会员制运营服务',
    icon: process.env.VUE_APP_STATIC_FILE_URL + '/mp/ProductServer/head_jifen.png',
    path: '/inthome',
  },
  {
    title: '盲盒商城(敬请期待)',
    subTitle: '助力搭建电商生态环境',
    icon: process.env.VUE_APP_STATIC_FILE_URL + '/mp/ProductServer/head_sass.png',
    // path: '/cloudElectricity',
  },
]

export default ProductHoverList
