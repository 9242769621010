<template>
  <div class="header-default">
    <div class="container h-full flex-row flex-col-center flex-row-between">
      <div class="header-logo">
        <img
          src="https://file.jingpingo.com/supply/material/3b56bcd8-239b-4755-9228-42cc2be40113.png"
          alt="logo"
          loading="lazy"
        />
      </div>
      <div class="collapse">
        <div class="title_block">
          <div
            class="title_li text-lg text-dark"
            v-for="(item, index) in HeaderConfig"
            :key="index"
            @mouseenter="enterLi(item.name)"
          >
            <a
              :href="item.path ? item.path : '#'"
              :class="{ active: hoverKey === index }"
            >
              {{ item.name }}
            </a>
          </div>
        </div>
        <div class="echat_login_after">
          <span @click="open">{{ isLogin ? "进入管理后台" : "登陆" }}</span>
          <span @click="logOut">退出</span>
        </div>
      </div>
    </div>
    <ProductServerHover v-model="ShowProductServerHover"></ProductServerHover>
    <SolutionHover v-model="ShowSolutionHover"></SolutionHover>
  </div>
</template>

<script>
/**
 * @author  XuHongli
 * @date  2022/12/22 14:41
 * @version 1.0
 * @description 默认头部
 */
import HeaderConfig from "@/config/HeaderConfig";
import ProductServerHover from "@/Layouts/components/ProductServerHover";
import SolutionHover from "@/Layouts/components/SolutionHover";
import ProductRouter from "@/router/ProductRouter";
import ServerRouter from "@/router/ServerRouter";
import SolutionRouter from "@/router/SolutionRouter";
import find from "lodash.find";
import Cookies from "js-cookie";
export default {
  name: "HeaderDefault",
  components: { SolutionHover, ProductServerHover },
  data() {
    return {
      HeaderConfig,
      ShowProductServerHover: false,
      ShowSolutionHover: false,
      process: process,
      isLogin: false,
      timer: null,
    };
  },
  mounted() {
    console.log(this.hoverKey);
    this.timer = setInterval(() => {
      this.isLogin =
        Cookies.get("access_token", { domain: "aijdan.com", path: "/" }) !==
          undefined &&
        Cookies.get("access_token", { domain: "aijdan.com", path: "/" }) !== "";
      console.log(this.isLogin);
    }, 1000);
  },
  computed: {
    hoverKey() {
      let tempKey = null;
      this.HeaderConfig.forEach((item, index) => {
        if (this.$route.path === item.path) {
          tempKey = index;
        }
      });

      // 寻找是否在产品服务里面
      if (tempKey === null) {
        let findObj = find([...ProductRouter, ...ServerRouter], (item) => {
          return this.$route.path === item.path;
        });
        if (findObj !== undefined) {
          return 2;
        }
      }

      // 寻找是否在解决方案路由内
      if (tempKey === null) {
        let findObj = find(SolutionRouter, (item) => {
          return this.$route.path === item.path;
        });
        if (findObj !== undefined) {
          return 3;
        }
      }

      return tempKey;
    },
  },
  methods: {
    open() {
      window.open(process.env.VUE_APP_SET_URL, "_blank");
    },
    logOut() {
      Cookies.remove("access_token", { domain: "aijdan.com", path: "/" });
      this.isLogin = false;
    },
    enterLi(name) {
      this.ShowProductServerHover = name === "产品服务";
      this.ShowSolutionHover = name === "解决方案";
    },
  },
};
</script>

<style scoped lang="scss">
.header-default {
  height: 64px;
  width: 100%;
  position: sticky;
  top: 0;
  background: hsla(0, 0%, 100%, 0.95);
  z-index: 999;
  &.shadow {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
  }

  .header-logo {
    position: relative;
    cursor: pointer;
    animation-duration: 1s;
    width: 126px;
    height: 41px;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.collapse {
  display: flex;
  align-items: center;
  height: 100%;
  .title_block {
    display: flex;
    align-items: center;
    height: 100%;
    .title_li {
      margin-right: 36px;
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;
      cursor: pointer;
      &:hover {
        a {
          color: #ff6a00;
        }
      }
      .active {
        color: #ff6a00;
      }
    }
  }
  .echat_login_after {
    display: flex;
    align-items: center;
    margin-left: 70px;
    span {
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      color: #333;
      margin: 0 10px;
      cursor: pointer;
      &:first-child {
        position: relative;
      }
      &:first-child:after {
        content: "";
        position: absolute;
        width: 2px;
        height: 15px;
        right: -11px;
        top: 5px;
        background: #888;
      }
    }
  }
}
</style>
