/**
 * @author  XuHongli
 * @date  2022/12/22 16:48
 * @version 1.0
 * @description 页脚按钮配置
 */

const FooterConfig = [
  {
    name: '加入我们',
    path: '/joinUs'
  },
  {
    name: '隐私声明',
    path: '/privacy'
  },
  {
    name: '用户协议',
    path: '/agreement'
  },
  {
    name: '渠道合作',
    path: '/channel'
  },
  {
    name: '招商入驻',
    path: '/invite'
  }
]

export default FooterConfig
