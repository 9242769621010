/**
 * @author  XuHongli
 * @date  2022/12/27 16:07
 * @version 1.0
 * @description
 */
const SolutionRouter = [
  {
    path: '/banking',
    name: 'Banking',
    meta: {
      title: '银行服务解决方案'
    },
    component: () => import('@/views/Solution/Banking')
  },
  {
    path: '/insurance',
    name: 'Insurance',
    meta: {
      title: '保险行业解决方案'
    },
    component: () => import('@/views/Solution/Insurance')
  },
  {
    path: '/politics',
    name: 'Politics',
    meta: {
      title: '政企服务解决方案'
    },
    component: () => import('@/views/Solution/Politics')
  },
  {
    path: '/retailers',
    name: 'Retailers',
    meta: {
      title: '企业采购解决方案'
    },
    component: () => import('@/views/Solution/Retailers')
  },
  {
    path: '/newRetail',
    name: 'NewRetail',
    meta: {
      title: '新零售行业解决方案'
    },
    component: () => import('@/views/Solution/NewRetail')
  },
  {
    path: '/sellRetail',
    name: 'SellRetail',
    meta: {
      title: '企业福利解决方案'
    },
    component: () => import('@/views/Solution/SellRetail')
  },
  {
    path: '/localizedService',
    name: 'LocalizedService',
    meta: {
      title: '本地化服务解决方案'
    },
    component: () => import('@/views/Solution/LocalizedService')
  },
  {
    path: '/enterprise',
    name: 'Enterprise',
    meta: {
      title: '企业费控解决方案'
    },
    component: () => import('@/views/Solution/Enterprise')
  },
]

export default SolutionRouter
