<template>
  <div>
    <router-view />
  </div>
</template>

<script>
/**
 * @author  XuHongli
 * @date  2022/8/29 11:04
 * @version 1.0
 * @description
 */
export default {
  name: 'LayoutNull'
}
</script>

<style scoped>

</style>
